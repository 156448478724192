import { getAbsoluteImageUrl } from './strapi/helpers';

export default function extractSeoData(seoData) {
  const keywords = seoData?.keywords?.split(' ').join(', ') || null;
  const ogImageUrl = getAbsoluteImageUrl(
    seoData?.metaImage?.data?.attributes?.url
  );
  const twitterSeoData = seoData?.metaSocial?.find(
    social => social.socialNetwork === 'Twitter'
  );
  const twitterImageUrl =
    getAbsoluteImageUrl(twitterSeoData?.image?.data?.attributes?.url) ||
    ogImageUrl;
  const twitterTitle = twitterSeoData?.title;
  const twitterDescription = twitterSeoData?.description;

  return {
    keywords,
    ogImageUrl,
    twitterTitle,
    twitterDescription,
    twitterImageUrl,
  };
}
