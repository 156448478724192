import { styled } from '@mui/material/styles';

export const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

export const ContentContainer = styled('div')(() => ({
  flex: '1 0 auto',
}));
