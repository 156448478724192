import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

/* Component used to unify margin below navigation bar */
const PageTopMarginWrapper = ({ navigationHidden, children, ...props }) => {
  return (
    <Box mt={{ xs: 7, md: navigationHidden ? 12 : 8 }} {...props}>
      {children}
    </Box>
  );
};

PageTopMarginWrapper.propTypes = {
  children: PropTypes.any.isRequired,
  navigationHidden: PropTypes.bool,
};

PageTopMarginWrapper.defaultProps = {
  navigationHidden: false,
};

export default PageTopMarginWrapper;
