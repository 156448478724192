import { styled } from '@mui/material/styles';

import PageMarginsBase from '@/components/ui/PageMargins/PageMargins';
import PageTopMarginWrapper from '@/components/ui/PageTopMarginWrapper/PageTopMarginWrapper';

export const Wrapper = styled(PageTopMarginWrapper)(({ theme }) => ({
  height: 700,
  background: `no-repeat center url("/images/hero-desktop.png")`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',

  // Remove margin from PartnersSection
  '& + * .MuiPaper-root': {
    marginTop: 0,
  },

  [theme.breakpoints.down('sm')]: {
    height: 'auto',
    marginBottom: theme.spacing(4.5),
    background: 'no-repeat center url("/images/hero-tablet.png")',
  },

  [theme.breakpoints.down(360)]: {
    background: 'no-repeat center url("/images/hero-mobile.png")',
  },
}));

export const FormWrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const PageMargins = styled(PageMarginsBase)(() => ({
  width: '100%',
}));
